<template>
  <div class="box">
    <router-link
      v-if="$can('admin/feedback/review-coupon')"
      :to="{ path: '/review/coupon' }"
    >
      <el-button
        type="primary"
        size="medium"
      >
        点评赠券
      </el-button>
    </router-link>
    <div class="box-toolbar">
      <div class="box-item">
        <el-select
          v-model="review_sum"
          placeholder="评分"
          @change="changeShopsStatus"
        >
          <el-option
            v-for="(item,index) in type"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box-item">
        <el-input
          placeholder="请输入反馈内容"
          v-model="search_name"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      height="67vh"
      :stripe="true"
      :data="lists"
    >
      <el-table-column
        width="80"
        label="序号"
        prop="id"
      >
      </el-table-column>

      <el-table-column
        label="评分"
        prop="review_sum"
      >
      </el-table-column>

      <el-table-column
        label="反馈内容"
        prop="review_body"
      >
      </el-table-column>

      <el-table-column
        label="反馈用户"
        prop="user_id"
      >
      <template slot-scope="scope">
        {{scope.row.user.username}}
      </template>
      </el-table-column>

      <el-table-column
        label="用户手机"
        prop="user_id"
      >
      <template slot-scope="scope">
        {{scope.row.user.phone}}
      </template>
      </el-table-column>

      <el-table-column
        label="反馈时间"
        prop="created_at"
      >
      </el-table-column>

      <el-table-column
        fixed="right"
        width="190"
        label="操作"
      >
        <template slot-scope="scope">
          <el-dropdown
            trigger="click"
          >
            <el-button
              plain
              size="mini"
              type="primary"
            >
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">

              <el-dropdown-item
                v-if="$can('admin/feedback/review-del')"
                @click.native="handleModelDelete(scope.row)"
              >
                删除
              </el-dropdown-item>

            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <div class="shop-i-bottom">
      <page
        class="shop-page"
        :pageSize="limit"
        :count="count"
        @change="pageChange"
        @changeSize="changeSize"
      >
      </page>
    </div>
  </div>
</template>

<script>

import LabelService from '@admin/services/LabelService'
// import flatry from '@admin/utils/flatry'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  name: 'FeedbackReview',
  data () {
    const Types = [
      { id: '', name: '全部' },
      { id: 0, name: '极差' },
      { id: 1, name: '非常差' },
      { id: 2, name: '差' },
      { id: 3, name: '一般' },
      { id: 4, name: '好' },
      { id: 5, name: '非常好' }
    ]
    return {
      type: Types,
      review_sum: '',
      search_name: '',
      function: function (param) {
        return LabelService.review(param)
      },
      delete: function (param) {
        return LabelService.reviewDel(param)
      }
    }
  },

  mixins: [pagination],
  components: { Page },

  methods: {
    async searchGo () {
      this.page = 1
      let param = {
        review_sum: this.review_sum,
        review_body: this.search_name
      }
      this.where = param
      await this.getList(param)
    },
    changeShopsStatus () {
      this.searchGo()
    }
  }

}

</script>
<style lang='' scoped>

</style>
